import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'app/shared/global.service';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})

export class LoginService {

   isSessionVaild = true;
   constructor(private http: HttpClient,
      private router: Router,
      private globals: GlobalService
   ) { this.isSessionVaild = true; }

   // Checking UserName And Password
   checkLogin(Login) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'login/Post', Login, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Checking UserName, Password and save MAC address
   checkRegistartion(Login) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'login/checkRegistartion', Login, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Geting Logged Out User
   GetLogoutUser(UserID: number, ClientID, UserName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Login/GetLogoutUser?UserID=' + UserID + '&ClientID=' + ClientID + '&UserName=' + UserName, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting EmailID Of User
   checkUser(UserName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'login/GetUserName?userName=' + UserName, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Sending EmailID Of User
   sendEmail(UserEmail) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post(environment.apiUrl + 'login/SendEmail?userEmail=' + UserEmail, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Sending EmailID Of User
   CheckValidPatientOrNot(Patient) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Login/CheckValidPatientOrNot', Patient, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Sending OTP To User
   sendOTP(UserName, MobileNo) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Login/sendOTP?userName=' + UserName + '&mobile=' + MobileNo, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Authentication Token For Patient
   getPatientAuth(PatientName, MobileNo) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Auth/PatientToken?patientName=' + PatientName
         + '&mobile=' + MobileNo, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   PostUserDetails(RegisterUser) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post(environment.apiUrl + 'Login/PostUserDetails', RegisterUser, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }
}
