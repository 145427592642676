import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import jwt_decode from "jwt-decode";
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'app/session/loginone/login.service';
import { GlobalService } from 'app/shared/global.service';

export const TOKEN_NAME: string = 'token';

@Injectable({
   providedIn: 'root'
})

export class AuthService {

   isLoggedIn = false;

   constructor(
      private router: Router,
      private toastr: ToastrService,
      private loginService: LoginService,
      private globals: GlobalService,
      private http: HttpClient) {

   }

   /*
    *  getLocalStorageUser function is used to get local user profile data.
    */
   getLocalStorageUser() {
      const token = sessionStorage.getItem(TOKEN_NAME);
      if (token) {
         this.isLoggedIn = true;
         return true;
      } else {
         this.isLoggedIn = false;
         return false;
      }
   }

   /*
  * signupUserProfile method save email and password into firabse &
  * signupUserProfile method save the user sign in data into local storage.
  */
   signupUserProfile(value) {
      // this.firebaseAuth.createUserWithEmailAndPassword(value.email, value.password)
      //  .then(value => {
      //   this.toastr.success('Account Created!');
      //   this.setToken(value);
      //   this.router.navigate(['/dashboard']);
      //  })
      //  .catch(err => {
      //   this.toastr.error(err.message);
      //  });
   }

   /*
    * resetPassword is used to reset your password.
    */
   resetPassword(value) {
      // this.firebaseAuth.sendPasswordResetEmail(value.email)
      //  .then((value) => {
      //   this.toastr.success("Email Sent");
      //   this.router.navigate(['/session/loginone']);
      //  })
      //  .catch(err => {
      //   this.toastr.error(err.message);
      //  });
   }

   /*
    * logOut function is used to sign out .
    */
   logOut() {
      const token = this.getDecodedToken();
      this.loginService.GetLogoutUser(token['nameid'],this.globals.ClientID,this.globals.UserName).subscribe((data) => {
         if (data == "OK") {
            sessionStorage.removeItem(TOKEN_NAME);
            this.isLoggedIn = false;
            this.toastr.success("You have been successfully logged out!");
            this.router.navigate(['/session/loginone']);
         }
      });
   }

   /*
    * Getting New Token For User
    */
   getRefreshToken() {
      const token = this.getToken();
      const decoded = jwt_decode(token);
      const user = decoded["unique_name"]
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Login/GetRefreshedToken?user=' + user, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   /*
    * setToken function is used to set local user profile data.
    */
   getToken(): string {
      return sessionStorage.getItem(TOKEN_NAME);
   }

   /*
    * setToken function is used to set local user profile data.
    */
   setToken(value) {
      // Storing Token into Session Storage
      sessionStorage.setItem(TOKEN_NAME, value);

      this.isLoggedIn = true;
   }

   /*
    * Getting Token Expiration Date
    */
   getTokenExpirationDate(token: string): Date {
      const decoded = jwt_decode(token);

      if (decoded["exp"] === undefined) return null;

      const date = new Date(0);
      date.setUTCSeconds(decoded["exp"]);
      return date;
   }

   /*
    * Getting Decoded Token
    */
   getDecodedToken(): any {
      // Getting Token From Session Storage
      const token = sessionStorage.getItem(TOKEN_NAME);
      const decoded = jwt_decode(token);
      return decoded;
   }

   /*
    * Checking Token Expired Or Not
    */
   isTokenExpired(token?: string): boolean {
      if (!token) token = this.getToken();
      if (!token) return true;

      const date = this.getTokenExpirationDate(token);
      if (date === undefined) return false;
      return !(date.valueOf() > new Date().valueOf());
   }

}
