import { Injectable } from '@angular/core';
import { CommonService } from 'app/shared/common.service';
import { GlobalService } from 'app/shared/global.service';


export interface ChildrenItems {
 state: string;
 name: string;
 type?: string;
 visible: boolean;
 icon?: string;
 label?: string;
 children?: ChildrenItems[];
}

export interface Menu {
 state: string;
 name: string;
 type: string;
 icon: string;
 visible: boolean;
 label?: string;
 children?: ChildrenItems[];
}

const MENUITEMS = [
 {
  state: 'horizontal',
  name: 'TOP MENU',
  type: 'link',
  visible: false,
  icon: 'icon-calendar icons'
 },
 {
  state: 'dashboard',
  name: 'DASHBOARD',
  type: 'link',
  visible: true,
  icon: 'icon-speedometer icons',
  label: 'New',
  children: [
   { state: 'dashboard-v1', name: 'DASHBOARD 1', visible: true },
   { state: 'dashboard-v2', name: 'DASHBOARD 2', visible: true },
  ]
 },
 {
  state: 'courses',
  name: 'COURSES',
  icon: 'icon-graduation icons',
  label: 'New',
  type: 'sub',
  visible: false,
  children: [
   { state: 'courses', name: 'COURSES', visible: false },
   { state: 'course-detail', name: 'COURSE DETAIL', visible: false },
   { state: 'course-signin', name: 'SIGN IN', visible: false },
   { state: 'course-payment', name: 'PAYMENT', visible: false }
  ]
 },
 {
  state: 'user-management',
  name: 'MANAGEMENT',
  type: 'sub',
  visible: false,
  icon: 'icon-organization icons',
  label: 'New',
  children: [
   { state: 'management-list', name: 'USER LIST', visible: false },
   { state: 'management-grid-list', name: 'USER GRID', visible: false }
  ]
 },
 {
  state: 'video-player',
  name: 'VIDEO PLAYER',
  type: 'link',
  visible: false,
  icon: 'icon-control-play icons',
  label: 'New'
 },
 {
  state: 'taskboard',
  name: 'TASK BOARD',
  type: 'link',
  visible: false,
  icon: 'icon-equalizer icons',
  label: 'New'
 },
 {
  state: 'user-pages',
  name: 'USER PAGES',
  type: 'sub',
  visible: false,
  icon: 'icon-people icons',
  label: 'New',
  children: [
   { state: 'user-list', name: 'USER LIST', visible: false },
   { state: 'user-table', name: 'USER TABLE', visible: false },
   { state: 'user-profile', name: 'USER PROFILE', visible: false },
   { state: 'user-profileV2', name: 'USER PROFILE V2', label: 'New', visible: false },
   { state: 'user-contact', name: 'USER CONTACT', visible: false }
  ]
 },
 {
  state: 'pages',
  name: 'PAGES',
  type: 'sub',
  visible: false,
  icon: 'icon-book-open icons',
  label: 'New',
  children: [
   { state: 'testimonials', name: 'TESTIMONIALS', visible: false },
   { state: 'timeline', name: 'TIMELINE', visible: false },
   { state: 'pricing', name: 'PRICING', visible: false },
   { state: 'pricingV2', name: 'PRICING V2', label: 'New', visible: false },
   { state: 'contact-list', name: 'CONTACT LIST', label: 'New', visible: false },
   { state: 'feedback', name: 'FEEDBACK', label: 'New', visible: false },
   { state: 'faq', name: 'FAQ', label: 'New', visible: false },
   { state: 'about', name: 'ABOUT', label: 'New', visible: false },
   { state: 'search', name: 'SEARCH', label: 'New', visible: false },
   { state: 'blank', name: 'BLANK', visible: false }
  ]
 },
 {
  state: 'session',
  name: 'SESSIONS',
  type: 'sub',
  visible: false,
  icon: 'icon-login icons',
  label: 'New',
  children: [
   { state: 'loginone', name: 'LOGIN', visible: false },
   { state: 'register', name: 'REGISTER', visible: false },
   { state: 'forgot-password', name: 'FORGOT', visible: false },
   { state: 'coming-soon', name: 'COMING SOON', visible: false },
   { state: 'coming-soonV2', name: 'COMING SOON V2', label: 'New', visible: false },
   { state: 'undermaintance', name: 'UNDER MAINTENANCE', visible: false },
   { state: 'maintanceV2', name: 'MAINTENANCE V2', label: 'New', visible: false },
   { state: 'lockscreen', name: 'LOCKSCREEN', visible: false },
   { state: 'subscribes', name: 'SUBSCRIBES', visible: false },
   { state: 'not-found', name: '404', visible: false },
  ]
 },
 {
  state: 'gallery',
  name: 'GALLERY',
  type: 'sub',
  visible: false,
  icon: 'icon-picture icons',
  label: 'New',
  children: [
   { state: 'galleryV1', name: 'GALLERY V1', visible: false },
   { state: 'galleryV2', name: 'GALLERY V2', visible: false },
   { state: 'galleryV3', name: 'GALLERY V3', visible: false },
   { state: 'galleryV4', name: 'GALLERY V4', label: 'New', visible: false },
  ]
 },
 {
  state: 'ecommerce',
  name: 'ECOMMERCE',
  type: 'sub',
  visible: false,
  icon: 'icon-handbag icons',
  label: 'New',
  children: [
   { state: 'ecommerce-product', name: 'PRODUCT', visible: false },
   { state: 'ecommerce-product-detail', name: 'PRODUCT DETAIL', visible: false },
   { state: 'ecommerce-product-list', name: 'PRODUCT LIST', visible: false },
   { state: 'ecommerce-product-cart', name: 'PRODUCT CART', visible: false },
   { state: 'ecommerce-order', name: 'ORDER', visible: false },
   { state: 'ecommerce-order-detail', name: 'ORDER DETAIL', visible: false },
   { state: 'invoice', name: 'INVOICE', visible: false },
   { state: 'invoiceV2', name: 'INVOICE LIST', label: 'New', visible: false },
  ]
 },
 {
  state: 'dash-widget',
  name: 'WIDGETS',
  type: 'link',
  visible: false,
  label: 'New',
  icon: 'icon-game-controller icons'
 },
 {
  state: 'inbox',
  name: 'INBOX',
  type: 'link',
  visible: false,
  icon: 'icon-envelope-letter icons'
 },
 {
  state: 'chat',
  name: 'CHAT',
  type: 'link',
  visible: false,
  icon: 'icon-bubbles icons'
 },
 {
  state: 'calendar',
  name: 'CALENDAR',
  type: 'link',
  visible: false,
  icon: 'icon-calendar icons'
 },
 {
  state: 'ui-elements',
  name: 'UI-ELEMENTS',
  type: 'sub',
  visible: false,
  icon: 'icon-equalizer icons',
  children: [
   { state: 'buttons', name: 'BUTTONS', visible: false },
   { state: 'progressbar', name: 'PROGRESS BAR', visible: false },
   { state: 'tabs', name: 'TABS', visible: false },
   { state: 'accordions', name: 'ACCORDIONS', visible: false },
   { state: 'pagination', name: 'PAGINATION', visible: false },
   { state: 'tooltip', name: 'TOOLTIP', visible: false },
   { state: 'badges', name: 'BADGES', visible: false },
   { state: 'cards', name: 'CARDS', visible: false },
   { state: 'social-icons', name: 'SOCIAL ICONS', visible: false },
   { state: 'typography', name: 'TYPOGRAPHY', visible: false },
   { state: 'dropdown', name: 'DROPDOWN', visible: false },
   { state: 'alert', name: 'ALERT', visible: false },
   { state: 'carousel', name: 'CAROUSEL', visible: false },
   { state: 'datepicker', name: 'DATEPICKER', visible: false },
  ]
 },
 {
  state: 'components',
  name: 'COMPONENTS',
  type: 'sub',
  visible: false,
  icon: 'icon-layers icons',
  children: [
   { state: 'list', name: 'LIST', visible: false },
   { state: 'grids', name: 'GRIDS', visible: false },
  ]
 },
 {
  state: 'icons',
  name: 'ICONS',
  type: 'sub',
  visible: false,
  icon: 'icon-flag icons',
  children: [
   { state: 'glyphicons', name: 'GLYPHICONS', visible: false },
   { state: 'fontawesome', name: 'FONTAWESOME', visible: false },
   { state: 'material-icons', name: 'MATERIAL ICONS', visible: false },
   { state: 'linea', name: 'LINEA', visible: false },
   { state: 'simple-line-icons', name: 'SIMPLE LINE ICONS', visible: false },
  ]
 },
 {
  state: 'forms',
  name: 'FORMS',
  type: 'sub',
  visible: false,
  icon: 'icon-doc icons',
  children: [
   { state: 'form-wizard', name: 'FORM WIZARD', visible: false },
   { state: 'form-elements', name: 'FORM ELEMENTS', visible: false },
   { state: 'form-group', name: 'FORM GROUP', visible: false },
   { state: 'form-validation', name: 'FORM VALIDATION', visible: false },
   { state: 'form-upload', name: 'UPLOAD', visible: false },
   { state: 'form-tree', name: 'TREE', visible: false }
  ]
 },
 {
  state: 'tables',
  name: 'TABLES',
  type: 'sub',
  visible: false,
  icon: 'icon-grid icons',
  children: [
   { state: 'basic', name: 'Basic', visible: false },
   { state: 'fullscreen', name: 'FULLSCREEN', visible: false },
   { state: 'selection', name: 'SELECTION', visible: false },
   { state: 'pinning', name: 'PINNING', visible: false },
   { state: 'sorting', name: 'SORTING', visible: false },
   { state: 'paging', name: 'PAGING', visible: false },
   { state: 'editing', name: 'EDITING', visible: false },
   { state: 'filter', name: 'FILTER', visible: false },
   { state: 'responsive', name: 'Responsive', visible: false },
   { state: 'foo', name: 'FOO', visible: false },
  ]
 },
 {
  state: 'editor',
  name: 'EDITOR',
  type: 'sub',
  visible: false,
  icon: 'icon-note icons',
  children: [
   { state: 'ace-editor', name: 'ACE EDITOR', visible: false },
   { state: 'summer-editor', name: 'SUMMER EDITOR', visible: false },
   { state: 'wysiwyg', name: 'WYSIWYG EDITOR', visible: false },
   { state: 'ckeditor', name: 'CKEDITOR', visible: false },
  ]
 },
 {
  state: 'chart',
  name: 'CHARTS',
  type: 'sub',
  visible: false,
  icon: 'icon-chart icons',
  children: [
   { state: 'ng2-charts', name: 'NG2 CHARTS', visible: false },
   { state: 'ngx-charts', name: 'NGX CHARTS', visible: false },
   { state: 'easy-pie-chart', name: 'EASY PIE', visible: false },
   { state: 'google-chart', name: 'GOOGLE CHARTS', visible: false },
  ]
 },
 {
  state: 'dragndrop',
  name: 'DRAG & DROP',
  type: 'sub',
  visible: false,
  icon: 'icon-share-alt icons',
  children: [
   { state: 'dragula', name: 'DRAGULA', visible: false },
   { state: 'sortable', name: 'SORTABLEJS', visible: false }
  ]
 },
 {
  state: 'resizable',
  name: 'RESIZABLE',
  type: 'link',
  visible: false,
  icon: 'icon-cursor-move icons'
 },
 {
  state: 'ngx-toaster',
  name: 'NGX TOASTER',
  type: 'link',
  visible: false,
  icon: 'icon-menu icons'
 },
 {
  state: 'animation',
  name: 'ANIMATION',
  type: 'link',
  visible: false,
  icon: 'icon-refresh icons fa-spin'
 },
 {
  state: 'maps',
  name: 'MAPS',
  type: 'sub',
  visible: false,
  icon: 'icon-map icons',
  children: [
   { state: 'googlemap', name: 'GOOGLE MAP', visible: false },
   { state: 'vectormap', name: 'VECTOR MAP', visible: false },
   { state: 'leafletmap', name: 'LEAFLET MAP', visible: false }
  ]
 },
 {
  state: 'blog',
  name: 'BLOG',
  type: 'sub',
  visible: false,
  icon: 'icon-notebook icons',
  children: [
   { state: 'blog-listing', name: 'BLOG LISTING', visible: false },
   { state: 'blog-grid', name: 'BLOG GRID', visible: false },
   { state: 'blog-masonry', name: 'BLOG MASONRY', visible: false },
   { state: 'blog-detail', name: 'BLOG DETAIL', visible: false },
   { state: 'blog-edit', name: 'BLOG EDIT', visible: false },
  ]
 },
 {
  state: 'master',
  name: 'MASTER',
  type: 'sub',
  visible: false,
  icon: 'icon-folder-alt icons',
  children: [
   { state: 'class', name: 'Class', type: 'link', visible: false },
   {
    state: 'staff', name: 'Staff', type: 'sub', visible: false,
    children: [
     { state: 'doctor', name: 'Doctor', type: 'link', visible: false },
     { state: 'employee', name: 'Employee', type: 'link', visible: false },
     { state: 'user', name: 'User', type: 'link', visible: false }
    ]
   }
  ]
 },
 {
  state: 'opd',
  name: 'OPD',
  type: 'sub',
  visible: true,
  icon: 'icon-folder-alt icons',
  children: [
   { state: 'appointment', name: 'Appointment', type: 'link', visible: true },
   { state: 'visit-mark', name: 'Visit Mark', type: 'link', visible: true },
  ]
 },
 {
  state: 'accounts',
  name: 'Accounts',
  type: 'sub',
  visible: true,
  icon: 'icon-folder-alt icons',
  children: [
   { state: 'cheque-clearing', name: 'Cheque Clearing', type: 'link', visible: true }
  ]
 }

];

const MenuStates = [
 "master", "opd", "ipd", "pathology", "radiology", "inventory",
 "reports", "tools", "accounts", "pharmacy", "operation-theatre","diagnostic-external-patient","shedule-master","analytics-dashboard"
];
const MenuIcons = {
 "master": "icon-folder-alt icons",
 "opd": "icon-folder-alt icons",
 "ipd": "icon-folder-alt icons",
 "pathology": "icon-folder-alt icons",
 "radiology": "icon-folder-alt icons",
 "inventory": "icon-folder-alt icons",
 "reports": "icon-folder-alt icons",
 "tools": "icon-folder-alt icons",
 "accounts": "icon-folder-alt icons",
 "pharmacy": "icon-folder-alt icons",
 "operation-theatre": "icon-folder-alt icons",
 "diagnostic-external-patient": "icon-folder-alt icons",
 "shedule-master": "icon-folder-alt icons",
 "analytics-dashboard": "icon-folder-alt icons",
};

@Injectable()
export class MenuItems {

 items = [];
 public MenuItemsList: Menu[] = [];

 constructor(private commonService: CommonService,
  private globals: GlobalService) {
  this.items = [];
 }
 updateMenuItems(userId: number) {
  if (userId <= 0) { return; }
  this.commonService.GetMenuItems(userId).toPromise().then((data) => {
   this.items = data;
   this.MenuItemsList = [];
   this.getAllHelper();
  });
 }

 // Menu Verify Using Index
 VerifyMenuIndex(item: any, idx: string) {
  if (idx == '0') {
   return item.MenuIndex == idx
    && MenuStates.find((e) => item.Path == e) != undefined;
  } else {
   return item.MenuIndex == idx;
  }
 }


 // VerifyMenuIndex(item: any, idx: string) {
 //     if(idx == '0'){}
 //     return item.MenuIndex == idx;
 // }


 parseMenuItem(menuItem: any) {
  if (menuItem == null || menuItem == undefined) { return null; }
  return {
   state: menuItem["Path"],
   name: menuItem["ComponentName"],
   type: menuItem["children"] != null && menuItem["children"] != undefined
    && menuItem["children"].length > 0 ? "sub" : "link",
   visible: true,
   icon: MenuIcons[menuItem["Path"]],
   children: []
  };
 }

 getAllHelper(): Menu[] {
  const itemsData = this.items;
  const verifyIndex = this.VerifyMenuIndex;
  const masterMenu = itemsData.filter((item) => verifyIndex(item, '0'));
  masterMenu.forEach((mainItem) => {
   mainItem.children = itemsData.filter((item) => verifyIndex(item, mainItem.ComponentID));
   mainItem.children.forEach((subMainItem) => {
    subMainItem.children = itemsData.filter((item) => verifyIndex(item, mainItem.ComponentID
     + "#" + subMainItem.ComponentID));
   });
  });

  const parsedMenu: Menu[] = [];
  /* Add dashboard menu */
  const dashboardMenuItem = {
   state: 'dashboard',
   name: 'DASHBOARD',
   type: 'link',
   visible: true,
   icon: 'icon-speedometer icons',
   label: '',
  };
  parsedMenu.push(dashboardMenuItem);

  for (const menuItems of masterMenu) {
   const menuIndex = masterMenu.findIndex((ele) => ele.ComponentID == menuItems["ComponentID"]);
   const menuItem = masterMenu[menuIndex];
   const menu = this.parseMenuItem(menuItem);
   if (menu && menu["type"] == "sub") {
    menu.children = [];
    for (const subMenuItems of menuItem["children"]) {
     const subMenuIndex = menuItem["children"].findIndex((ele) => ele.ComponentID == subMenuItems["ComponentID"]);
     const subMenuItem = menuItem["children"][subMenuIndex];
     const subMenu = this.parseMenuItem(subMenuItem);
     if (subMenu && subMenu["type"] == "sub") {
      subMenu.children = [];
      for (const subSubMenuItems of subMenuItem["children"]) {
       const subSubMenuIndex = subMenuItem["children"].findIndex((ele) => ele.ComponentID == subSubMenuItems["ComponentID"]);
       const subSubMenuItem = subMenuItem["children"][subSubMenuIndex];
       const subSubMenu = this.parseMenuItem(subSubMenuItem);
       if (subSubMenu) {
        subMenu.children.push(subSubMenu);
       }
      }
     }
     menu.children.push(subMenu);
    }
   }
   parsedMenu.push(menu);
  }

  this.MenuItemsList = parsedMenu;
  return this.MenuItemsList;
 }
}
