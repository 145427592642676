<!-- Main-->
<div id="app" class="app {{headerSkinColor}} {{themeSkinColor}}" [dir]="layout" [class.app-dark]="dark"
  [class.boxed]="boxed" [class.collapsed-sidebar]="collapseSidebar" [class.compact-sidebar]="compactSidebar"
  [class.customizer-in]="customizerIn" [class.chat-window--open]="chatWindowOpen" [class.sidebar-closed]="sidebarClosed"
  [class.sidebar-opened]="!sidebarClosed" [class.chat-sidebar]="chatSidebar"
  [ngClass]="{'icon-sidebar-wrap': miniSidebar}">
  <ng-sidebar-container>
    <div ng-sidebar-content>
      <ng-sidebar #sidenav [(opened)]="_opened" id="sideNavMenu" [(mode)]="_mode"
        [closeOnClickOutside]="closeOnClickOutside()" [(showBackdrop)]="_showBackdrop">
        <div class="sidebar-panel epion-sidenav icon-sidebar" id="showSidebar">
          <div class="sidebar-logo-wrap">
            <div class="sidebar-logo d-flex">
              <div class="logo-sign">
                <img src="assets/img/logo-sign.png" alt="Epion">
              </div>
              <div class="logo-text mx-2 align-self-center">
                <img src="assets/img/epion-logo.png" height="50">
              </div>
            </div>
          </div>
          <div class="sidebar-container">
            <div class="user-section text-center">
              <div class="sidebar-user-wrap">
                <div class="sidebar-user-inner">
                  <div class="d-flex">
                    <div class="thumb-wrap mb-1"><img alt="Epion Admin" class="img-fluid" src="assets/img/user-3.jpg"
                        width="90" height="90"></div>
                    <div class="user-action">
                      <a href="javascript:void(0)" class="text-dark"><i class="material-icons">more_horiz</i></a>
                      <div class="sidebar-user-menu">
                        <ul>
                          <li><a routerLink="/user-pages/user-profile"><i class="icon-user icons"></i>
                              <span>{{'My Profile'|translate}}</span></a></li>
                          <li><a routerLink="/inbox"><i class="icon-envelope icons"></i>
                              <span>{{'My Inbox'|translate}}</span></a></li>
                          <li><a routerLink="/session/loginone" id="btnLogout" (click)="logOut()"><i
                                class="icon-logout icons"></i><span>{{'Logout'|translate}}</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="sidebar-user-info-wrap">
                    <div class="sidebar-user-name">David Williams</div>
                  </div>
                </div>
              </div>
            </div>
            <perfect-scrollbar class="sidenav-scrollbar-container">
              <div>
                <nav menuToggleDirective class="navigation">
                  <ng-container *ngIf="this.HasClientDetails()">
                    <ul class="epion-nav">
                      <li class="nav-item" menuToggleLink *ngFor="let menuitem of menuItems.MenuItemsList"
                        [ngClass]="{'menu-item-has-child': menuitem.type=='sub'}">
                        <ng-container *ngIf="menuitem.visible">
                          <a menuToggle [routerLink]="['/', menuitem.state]" routerLinkActive="active-link"
                            *ngIf="menuitem.type === 'link'">
                            <i class="{{ menuitem.icon }}"></i>
                            <p class="text-nowrap mb-0">
                              <span>{{ menuitem.name | translate }}</span>
                            </p>
                            <ng-container *ngIf="menuitem.label">
                              <span class="sidebar-label">{{menuitem.label}}</span>
                            </ng-container>
                          </a>
                          <a menuToggle href="javascript:void(0)" *ngIf="menuitem.type === 'sub'">
                            <i class="{{ menuitem.icon }}"></i>
                            <p class="text-nowrap mb-0">
                              <span>{{ menuitem.name | translate }}</span>
                            </p>
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                            <ng-container *ngIf="menuitem.label">
                              <span class="sidebar-label">{{menuitem.label}}</span>
                            </ng-container>
                          </a>
                          <ul class="sub-menu epion-nav" *ngIf="menuitem.type === 'sub'">
                            <li class="nav-item" *ngFor="let childitem of menuitem.children" menuToggleLink>
                              <ng-container *ngIf="childitem.visible && childitem.type === 'link'">
                                <a *ngIf="childitem.type === 'link'"
                                  [routerLink]="['/', menuitem.state, childitem.state ]" menuToggle
                                  routerLinkActive="active-link">
                                  <i class="icon icon-arrows-right-double"></i>
                                  <p class="text-nowrap mb-0">
                                    <span>{{ childitem.name | translate }}</span>
                                  </p>
                                  <ng-container *ngIf="childitem.label">
                                    <span class="sidebar-label">{{childitem.label}}</span>
                                  </ng-container>
                                </a>
                              </ng-container>
                              <ng-container *ngIf="childitem.visible && childitem.type === 'sub'">
                                <div class="sub-sub-menu-dropdown">
                                  <a *ngIf="childitem.type === 'sub'" class="sub-sub-menu-link"
                                    [routerLink]="['/', menuitem.state, childitem.state ]" menuToggle
                                    routerLinkActive="active-link">
                                    <i class="icon icon-arrows-right-double"></i>
                                    <p class="text-nowrap mb-0">
                                      <span>{{ childitem.name | translate }}</span>
                                    </p>
                                    <ng-container *ngIf="childitem.label">
                                      <span class="sidebar-label">{{childitem.label}}</span>
                                    </ng-container>
                                  </a>
                                  <ul class="sub-sub-menu" *ngIf="childitem.type === 'sub'">
                                    <li class="nav-item" *ngFor="let childOfChildItem of childitem.children"
                                      menuToggleLink>
                                      <ng-container *ngIf="childOfChildItem.visible">
                                        <a *ngIf="childOfChildItem.type === 'link'"
                                          [routerLink]="['/', menuitem.state, childitem.state, childOfChildItem.state ]"
                                          menuToggle routerLinkActive="active-link">
                                          <i class="icon icon-arrows-right-double"></i>
                                          <p class="text-nowrap mb-0">
                                            <span>{{ childOfChildItem.name | translate }}</span>
                                          </p>
                                          <ng-container *ngIf="childOfChildItem.label">
                                            <span class="sidebar-label">{{childOfChildItem.label}}</span>
                                          </ng-container>
                                        </a>

                                      </ng-container>
                                    </li>
                                  </ul>
                                </div>
                              </ng-container>
                            </li>
                          </ul>
                        </ng-container>
                      </li>
                    </ul>
                  </ng-container>
                  <h5 style="font-size: 12px;font-weight: 600;text-align: center;">
                    {{ this.globals.CurrentDateTime | date : "dd-MM-yyyy hh:mm a" }}
                  </h5>
                  <!-- <ul class="add-dynamic-menu list-style-none pb-5 pb-md-3">
          <li class="mb-5 mb-sm-4 mb-md-0 nav-item">
           <a (click)="addMenuItem()">
            <i class="fa fa-plus" aria-hidden="true"></i>
            <p>{{'Add'|translate}}</p>
           </a>
          </li>
         </ul> -->
                </nav>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <!-- sidebar panel closed -->
      </ng-sidebar>
      <section class="main-contents">
        <header class="main-header">
          <div class="d-flex justify-content-between align-items-center epion-header-toolbar w-100">
            <div class="h-100 d-flex align-items-center col-sm-6 col-md-6 col-lg-6 pl-0 pr-0">
              <div class="h-100 d-flex justify-content-start align-items-center">
                <div class="h-100 epion-ham-icon">
                  <button class="c-hamburger c-hamburger--rot" id="toggleButton" (click)="_toggleOpened()" title="Menu">
                    <span tourAnchor="start.tour">toggle menu</span>
                  </button>
                </div>
              </div>



              <!-- <div class="h-100 pl-4 search-bar w-100 h-100 d-none d-md-block search-w">
                <form class="h-100 search-form">
                  <div class="h-100 search-i">
                    <input tourAnchor="tour-search" class="form-control" title="Work In Progress" type="text"
                      placeholder="Search" data-toggle="modal" data-target="#search-modal" id="search-field" />
                  </div>
                </form>
              </div> -->
              <div class="h-100 pl-4 search-bar w-100 h-100 d-none d-md-block search-w d-flex-justify-content-end">
                <form class="h-100 search-form d-flex justify-content-end">
                  <div class="h-100 mt-3">
                    <h1 class="text-primary" style="font-family: Algerian;">{{globals.title}}</h1>
                  </div>
                </form>
              </div>




            </div>
            <div class="align-items-end pr-0 col-sm-6 col-md-6 col-lg-6">
              <div class="d-flex secondary-menu justify-content-end align-items-center">
                <ul class="nav secondary-menu-list d-flex justify-content-end align-items-center"
                  data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                  <!-- <li tourAnchor="tour-full-screen" class="d-md-block" title="ABHA">
                    <div class="btn-group mr-4">
                      <button type="button" class="btn btn-primary" data-toggle="dropdown" aria-expanded="false"
                        disabled> ABHA
                      </button>
                      <button type="button"
                        [ngClass]="isInProgress ? 'spinner-border spinner-border-sm mr-1' : 'btn  btn-primary dropdown-toggle btn-label-primary'"
                        data-toggle="dropdown" aria-expanded="false">
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu text-left" style="width: 10px; height: 70px; overflow: auto">
                        <li *ngFor="let cc of ABHAList" class="text-primary dropdown-item"
                          (click)="ShowABDMFlow(cc === 'Create ABHA' ? 'create' : 'verify')">{{cc}}</li>
                      </ul>
                    </div>
                  </li> -->
                  <li *ngIf="globals.VideoConferenceVisible" tourAnchor="tour-full-screen"
                    (click)="RouteVideoConferenceRoom(0);" class="d-md-block" title="Video Conference">
                    <a href="javascript:void(0)" class="zoom-out">
                      <!-- <i class="material-icons" >tv</i> -->
                      <i class="fa fa-video-camera text-dark fa-lg"></i>
                    </a>
                  </li>
                  <li tourAnchor="tour-full-screen" class="d-none d-md-block" title="Go To Dashboard">
                    <a href="javascript:void(0)" class="zoom-out">
                      <i class="material-icons" (click)="GetDashboardpage();" *ngIf="!isFullscreen">tv</i>
                      <i class="material-icons" *ngIf="isFullscreen">fullscreen_exit</i>
                    </a>
                  </li>
                  <li class=" d-none d-md-block dropdown fav-links">
                    <a href="javascript:void(0)" class="rotate-90" id="navbarD" title="Work In Progress"
                      aria-haspopup="true" aria-expanded="false"> <i class="material-icons">apps</i></a>
                    <div class="dropdown-menu rounded-0 dropdown-menu-right dropdown-menu-lg p-0"
                      aria-labelledby="navbarD">
                      <div class="dropdown-header bg-light d-flex justify-content-between">
                        <span class="align-self-center">{{'Quick Links'|translate}}</span>
                      </div>

                      <a class="dropdown-item pt-3" routerLink="/dashboard/dashboard-v2">
                        <div class="icon-area ">
                          <i class="material-icons">dashboard</i>
                        </div>
                        <small>{{'Dashboard 2'|translate}}</small>
                      </a>
                      <a class="dropdown-item pt-3" routerLink="/chat">
                        <div class="icon-area ">
                          <i class="material-icons">chat_bubble_outline</i>
                        </div>
                        <small>{{'Chat'|translate}}</small>
                      </a>
                      <a class="dropdown-item pt-3" routerLink="/inbox">
                        <div class="icon-area ">
                          <i class="material-icons">email</i>
                        </div>
                        <small>{{'Email'|translate}}</small>
                      </a>
                      <a class="dropdown-item pt-3" routerLink="/blog/blog-grid">
                        <div class="icon-area ">
                          <i class="material-icons">grid_on</i>
                        </div>
                        <small>{{'Blog Grid'|translate}}</small>
                      </a>
                      <a class="dropdown-item pt-3" routerLink="/gallery/galleryV1">
                        <div class="icon-area ">
                          <i class="material-icons">collections</i>
                        </div>
                        <small>{{'Gallery'|translate}}</small>
                      </a>
                      <a class="dropdown-item pt-3" routerLink="/ecommerce/ecommerce-product">
                        <div class="icon-area ">
                          <i class="material-icons">shopping_cart</i>
                        </div>
                        <small>{{'Shop'|translate}}</small>
                      </a>
                    </div>
                  </li>
                  <li class="d-none d-md-block dropdown">
                    <a href="javascript:void(0)" id="navbarDropdownMenu" class="faa-parent animated-hover"
                      title="Work In Progress" aria-haspopup="true" aria-expanded="false"><i
                        class="notifi-bell material-icons faa-slow faa-ring">notifications</i>
                      <span class="fa fa-circle fa-notify text-primary dot-note"></span></a>
                    <div class="dropdown-menu rounded-0 dropdown-menu-right dropdown-menu-lg p-0"
                      aria-labelledby="navbarDropdownMenu">
                      <div class="dropdown-header bg-primary d-flex justify-content-between">
                        <span class="align-self-center">{{'12 Pending notifications'|translate}}</span>
                        <a href="javascript:void(0)" class="align-self-center">{{'View All'|translate}}</a>
                      </div>
                      <a class="dropdown-item  d-flex pt-3" href="javascript:void(0)">
                        <div class="icon-area  align-self-start">
                          <i class="fa fa-envelope-o"></i>
                        </div>
                        <div class="icon-msg mx-2">
                          <span class="align-self-start d-block ">{{'You have 16 New messages'|translate}}</span>
                          <small>12 min ago</small>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex" href="javascript:void(0)">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                        </div>
                        <div class="icon-msg mx-2"> <span class="align-self-start d-block ">{{'Server reboot scheduled'
                            |
                            translate}}</span>
                          <small>12 min ago</small>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex pb-3" href="javascript:void(0)">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                        </div>
                        <div class="icon-msg mx-2"> <span class="align-self-start d-block ">{{'Revenue raised by 67%' |
                            translate}}</span>
                          <small>12 min ago</small>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="d-none d-md-block dropdown">
                    <a href="javascript:void(0)" class="open-box" id="navbarDropdownMen" title="Work In Progress"
                      aria-haspopup="true" aria-expanded="false"><i class="material-icons msg-close">mail_outline</i><i
                        class="fa fa-envelope-open-o"></i><span
                        class="fa fa-circle fa-notify dot-note text-danger"></span></a>
                    <div class="dropdown-menu rounded-0 dropdown-menu-right dropdown-menu-lg p-0"
                      aria-labelledby="navbarDropdownMen">
                      <div class="dropdown-header bg-warning d-flex justify-content-between">
                        <span class="align-self-center">{{'15 total messages'|translate}}</span>
                        <a href="javascript:void(0)" class="align-self-center">{{'View All'|translate}}</a>
                      </div>
                      <a class="dropdown-item  d-flex pt-3" [routerLink]="['/inbox']"
                        [routerLinkActive]="['is-active']">
                        <div class="icon-area  align-self-start">
                          <i class="fa fa-envelope-o"></i>
                        </div>
                        <div class="icon-msg mx-2"> <span class="align-self-start d-block ">{{'Inbox'|translate}}</span>
                          <small>15 new messages</small>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex" [routerLink]="['/inbox']" [routerLinkActive]="['is-active']">
                        <div class="icon-area  align-self-start">
                          <i class="fa fa-gift" aria-hidden="true"></i>
                        </div>
                        <div class="icon-msg mx-2"> <span class="align-self-start d-block ">{{'Promotion messages'|
                            translate}}</span>
                          <small>5 messages</small>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex" [routerLink]="['/inbox']" [routerLinkActive]="['is-active']">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </div>
                        <div class="icon-msg mx-2">
                          <span class="align-self-start d-block ">{{'Spam message'|translate}}</span>
                          <small>12 min ago</small>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex pb-3" href="javascript:void(0)">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-bullhorn" aria-hidden="true"></i>
                        </div>
                        <div class="icon-msg mx-2"> <span class="align-self-start d-block ">{{'Social Media'|
                            translate}}</span>
                          <small>34 new messages</small>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="dropdown">
                    <a class="relative rounded-bg" href="javascript:void(0)" id="navbarDropdownMenuLin"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                      <img class="rounded-circle" src="assets/img/user-3.jpg" alt="User-image" width="43" height="43"
                        title="ClientName">




                      <div class="online-caret bg-primary circle"><i class="fa fa-caret-down"></i>
                      </div>
                    </a>
                    <div class="dropdown-menu rounded-0 dropdown-menu-right p-0"
                      aria-labelledby="navbarDropdownMenuLin">
                      <a class="dropdown-item  d-flex pt-3">
                        <div class="icon-area  align-self-start">
                          <i class="fa fa-user"></i>
                        </div>
                        <div class="icon-msg mx-2">
                          <span class="align-self-start d-block ">{{globals.UserName|translate}}</span>
                        </div>
                      </a>
                      <!-- <a class="dropdown-item d-flex" routerLink="/user-pages/user-contact" *ngIf="false">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-money"></i>
                        </div>
                        <div class="icon-msg mx-2">
                          <span class="align-self-start d-block ">{{'My Contact'|translate}}</span>
                        </div>
                      </a> -->
                      <a class="dropdown-item  d-flex pb-2" *ngIf="globals.ChangePasswordVisible"
                        routerLink="/tools/change-password">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-cog"></i>
                        </div>
                        <div class="icon-msg mx-2">
                          <span class="align-self-start d-block ">{{'Update Password'|translate}}</span>
                        </div>
                      </a>
                      <a class="dropdown-item  d-flex pb-2" (click)="logOut()">
                        <div class="icon-area align-self-start">
                          <i class="fa fa-power-off"></i>
                        </div>
                        <div class="icon-msg mx-2"><span class="align-self-start d-block ">{{'Logout'|translate}}</span>
                        </div>
                      </a>
                    </div>
                  </li>
                  <li class="d-none d-md-block dropdown">
                    <a title="Work In Progress" class="zoom-out"><i class="material-icons">more_horiz</i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
        <!-- <div class="message alert" *ngIf="isExpiry">
          <marquee class="host" style="width:75%;" scrollamount="10" onmousemove="this.stop();"
            onmouseleave="this.start();">Your
            Epion Software subscription license is expiring
            soon. To renew
            your subscription license, please contact 7030560606 or 9511209020. </marquee>
          <div class="alert-close text-danger" (click)="isExpiry = false"> X </div>
        </div> -->

        <!-- main header closed -->
        <div class="epion-base-container" #scrollContainer>
          <label class="message alert" *ngIf="isExpiry">
            <marquee class="host" style="width:75%;" scrollamount="10" onmousemove="this.stop();"
              onmouseleave="this.start();">Your
              Epion Software subscription license is expiring
              soon. To renew
              your subscription license, please contact 7030560606 or 9511209020. </marquee>
            <div class="alert-close text-danger" (click)="isExpiry = false"> X </div>
          </label>
          <div class="d-flex justify-content-between align-items-center page-info w-100 d-none">
            <span class="navbar-brand">{{header}}</span>
          </div>
          <div class="inner-container">


            <router-outlet (activate)="onActivate($event)"></router-outlet>
            <tour-step-template>
              <ng-template let-step="step">
                <div (click)="$event.stopPropagation()">
                  <p class="tour-step-content">{{step?.content}}</p>
                  <div class="tour-step-navigation">
                    <button *ngIf="tourService.hasPrev(step)" class="btn btn-sm" (click)="tourService.prev()">
                      <i class="material-icons">arrow_back</i> Prev</button>
                    <button *ngIf="tourService.hasNext(step)" class="btn btn-sm" (click)="tourService.next()">Next
                      <i class="material-icons">arrow_forward</i></button>
                    <button class="btn btn-sm btn-socondary rounded-0" (click)="tourService.end()">End</button>
                  </div>
                </div>
              </ng-template>
            </tour-step-template>
          </div>
        </div>
        <!-- epion base container closed -->
        <!-- footer -->
        <!-- <footer>
     <div class="container-fluid">
      <div class="row">
       <div class="col-12">
        <span id="siteseal">
         <script async type="text/javascript"
          src="https://seal.godaddy.com/getSeal?sealID=JCPJihL5dm1kShdNTwgyBsDyTLnqesS1U4HvVqGxzjv1T8M1dRKsPAy2m01l"></script>
        </span>
       </div>
      </div>
     </div>
    </footer> -->
        <!-- footer end -->
      </section>
      <!-- main content closed -->
    </div>
    <!-- Sidebar Closed -->
  </ng-sidebar-container>
  <!-- Sidebar -->
  <div class="epion-customizer">

    <a class="customizer-toggle bg-primary faa-parent animated-hover d-none" (click)="customizerFunction()">
      <i class="fa fa-wrench faa-slow faa-wrench" aria-hidden="true"></i>
    </a>

    <a title="Internal Chat"
      [ngClass]="globals.ChangeInternalChatCount == true ? 'customizer-toggle bg-primary faa-parent animated-hover fa-lg blink_me':'customizer-toggle bg-primary faa-parent animated-hover fa-lg'"
      *ngIf="globals.InternalChatVisible">
      <i class="fa-lg icon-bubbles icons" (click)="AddInternalChatPopUp(content2)"></i>
    </a>


    <!-- setting panel Closed -->
  </div>
  <!-- Epion customize -->

  <!-- Chat Sidebar -->
  <!-- <div class="modal fade search-modal" id="search-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
  <div class="modal-dialog" role="document">
   <div class="container">
    <div class="mb-5">
     <input class="overlay-search" placeholder="Search...">
    </div>
    <div class="search-suggestion">
     <span class="d-block mb-3"><strong>Sugguestions</strong></span>
     <ul class="row search-list">
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">A</span><span
         class="search-filter mx-1">admin themes</span> in angular4</a>
       <span class="d-block">@themeforest</span>
      </li>
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">H</span><span
         class="search-filter mx-1">5 Star Hotels</span> in hotal</a>
       <span class="d-block">@abcdehotels</span>
      </li>
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">F</span><span
         class="search-filter mx-1">air ticket</span> in flights</a>
       <span class="d-block">@airindia</span>
      </li>
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">D</span><span
         class="search-filter mx-1">css design</span> in designs</a>
       <span class="d-block">@csstricks</span>
      </li>
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">C</span><span
         class="search-filter mx-1">programming</span> in code</a>
       <span class="d-block">@abcdproject</span>
      </li>
      <li class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
       <a href="javascript:void(0)"><span class="d-inline-block square-50 circle bg-success mr-2">M</span><span
         class="search-filter mx-1">dunkirk</span> in movies</a>
       <span class="d-block">@imdb</span>
      </li>
     </ul>
    </div>
   </div>
  </div>
 </div> -->
  <!-- Modal -->

  <!-- Chat Window -->
</div>
<!-- app closed -->

<!--------------------------------------------- Internal Chat Pop Up ------------------------------------------------------------>
<ng-template #content2 let-ca="close('a')">
  <div class="chat-wrapper fade-in-up">
    <div class="row no-gutters bg-white">
      <div class="col-xl-4">
        <div class="inner-tab">
          <ul class="list-group list-unstyled">
            <li class="list-group-item rounded-0 bg-dark ">
              <div class="search-list w-100">
                <div>
                  <div class="form-group">
                    <label class="text-white">Select New User</label>
                    <select class="form-select form-control" (change)="GetSelectedUser()" name="User1" id="User1"
                      required [(ngModel)]='itemToEdit.ToUserID'>
                      <option disabled value="0">Select New User</option>
                      <option *ngFor='let cc of user' [value]="cc['UserID']">

                        {{cc['EmployeeName' ] + " ( " + cc['UserName' ] + " )"}}

                    </select>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="chk-inbox-sidebar" style="overflow: auto; min-height: 500px; max-height: 500px;">
            <ul class="nav nav-tabs" role="tablist">

              <li class="nav-item mt-1" *ngFor="let item of Useritems">
                <a [ngClass]="item.EmployeeName == showingName ? 'nav-link active ripple' : 'nav-link ripple'"
                  data-toggle="tab" href="#tab-3" role="tab"
                  (click)="AddNewInternalChat(item.InternalChatID,item.EmployeeName)">
                  <div class="d-flex justify-content-start">
                    <div class="row">
                      <div class="col-lg d-flex align-items-start flex-column mr-3 pos-relative">
                        <img src="assets/img/demoUser.png" class="rounded-circle" width="55" height="55"
                          alt="user thumb" />
                        <span class="badge badge-success square-10 top-right-badge fa-notify"></span>
                      </div>
                    </div>
                    <div class="col-lg align-items-start flex-column m-w-160">
                      <h5>{{item.EmployeeName + " - " + item.FromUser }}</h5>
                      <small class="text-muted">{{item.Date | date : 'dd-MM-yyyy hh:mm a' }}</small>
                    </div>
                    <div class="col-lg d-flex justify-content-end mt-3">
                      <i class="fa fa-times" (click)="DeactivateById(itemToEdit.InternalChatID,0)"></i>
                    </div>
                  </div>

                </a>
              </li>
            </ul>
          </div>
          <!-- Chk inbox sidebar Closed -->
        </div>
        <!-- Inner Tab Closed -->
      </div>
      <div class="col-xl-8">
        <div class="chk-block mb-0">
          <div class="chk-block-title bg-light">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-content-start">
                <img src="assets/img/demoUser.png" class="rounded-circle" width="55" height="55" alt="user thumb" />
                <h3 class="text-capitalize mt-2 ml-2">{{showingName}}</h3>
              </div>
              <div class="d-flex align-content-end">
                <ul class="inbox-action list-inline">
                  <li class="list-inline-item">
                    <a><i class="fa fa-video-camera fa-lg" title="Work in progress" aria-hidden="true"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a><i class="fa fa-phone fa-lg" title="Work in progress" aria-hidden="true"></i></a>
                  </li>
                  <li class="list-inline-item">
                    <a><i class="fa fa-times fa-lg text-danger" title="Close" aria-hidden="true" (click)="ca"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Chk Block Title Closed -->
          <div class="chk-block-content mt-1">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
                <div class="chat-wrapper" style="overflow: auto; min-height: 400px; max-height: 400px;">

                  <div class="media container-fluid" *ngFor="let comment of itemToEdit.MsgItems;let i= index">
                    <img *ngIf="comment.Type != 'S'" class="d-flex mr-3 img-fluid rounded-circle"
                      src="assets/img/demoUser.png" alt="thumb image" width="40" height="40">
                    <div class="media-body">
                      <div [ngStyle]="{'background-color' : comment.Type == 'S' ? '' : '#k7dye7'}"
                        [ngClass]="comment.Type == 'S' ? 'chat-content sender bg-primary': 'chat-content receiver' "
                        style="border-radius: 10px;">
                        <p
                          [ngClass]="comment.Type != 'S' ? 'd-flex justify-content-start': 'd-flex justify-content-end'">
                          {{comment.Message}}
                        </p>

                      </div>
                      <p
                        [ngClass]="comment.Type != 'S' ? 'd-flex justify-content-start text-muted small mt-1': 'd-flex justify-content-end text-muted small mt-1' ">
                        {{comment.Date}}
                        <i class="fa fa-trash ml-1 mt-1 text-danger"
                          (click)="DeactivateById(0,comment.InternalChatDetailsID)"></i>



                      </p>
                    </div>
                    <img *ngIf="comment.Type == 'S'" class="d-flex mr-3 img-fluid rounded-circle"
                      src="assets/img/demoUser.png" alt="thumb image" width="40" height="40">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="send-msg  p-4 bg-light m-0">
            <div class="input-group">
              <textarea #textArea class="form-control" [style.height]="height" name="Message" id="Message"
                [(ngModel)]='commentMessage' (input)="changeHeight(textArea)"></textarea>

              <span class="input-group-append">
                <!-- <button class="btn btn-primary btn-lg" type="button" (click)="sendComment(0)">Reply</button> -->
                <button type="button" class="btn btn-primary " (click)="sendComment(0)">
                  <i class="fa fa-send"></i>
                </button>
              </span>
            </div>
          </div>
          <!-- Send Msg Closed -->
        </div>
        <!-- Chk Block Content -->
      </div>
    </div>
  </div>
</ng-template>