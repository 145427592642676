import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
   providedIn: 'root'
})
export class CommonService {

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }

   //  Loads all Client
   getClientList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting ehrKeyword
   GetEhrKeywords(DepartmentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEhrKeywords?DepartmentID=' + DepartmentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Locations using ClientID
   getLocationsList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLocationList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads Control Name using ClientID and ComponentID
   getControlName(ClientID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetControlName?clientID=' + ClientID + '&componentID=' + ComponentID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all appointment from database
   // This gives call to Get method from AppointmentController
   getAppointment(FromDate, ToDate, ClientID, LocationID, ComponentID, PreviousDateTransaction) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Appointment/GetByFilters?FromDate=' + FromDate + '&ToDate=' + ToDate
         + '&clientID=' + ClientID + '&locationID=' + LocationID + '&componentID=' + ComponentID
         + '&previousDateTransaction=' + PreviousDateTransaction, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Get Menu Visibilty For user By Using User ID
   GetMenuVisbleForUserByUserID(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMenuVisibility?userID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets ReceiptBook List
   getAllReceiptBookList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllReceiptBookList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets Client Group And Service List Fo rDefault Service Doctor
   GetClientGroupAndServiceListForDefaultServiceDoctor(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientGroupAndServiceListForDefaultServiceDoctor?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //   Get Client Default Service Doctor List
   GetClientDefaultServiceDoctorList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientDefaultServiceDoctorList?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //   Get receipt book list for OPD or IPD
   GetOpIpReceptBookList(ClientID, LocationID, RecpBkType) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetOpIpReceptBookList?ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&RecpBkType=' + RecpBkType, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets Client Name And Location Name By Using ID
   getClientNameLocationName(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientLocationName?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Check valid token or not
   GetCheckValidToken(UserID) {
      const data = { 'token': sessionStorage.getItem('token'), UserID: UserID };
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Common/GetCheckValidToken', data, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all LoginTemplate List
   getLoginTemplateList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'User/GetLoginTemplateList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //  Loads all locations
   getLocationList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLocationList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting All Common Department List
   GetAllDepartmentList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllDepartmentList?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Departments
   getDepartmentList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDepartmentList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Departments
   GetClientReportList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientReportList?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Get Previous Patient Data by client location
   GetPreviousPatientData(ClientID, LocationID, Symptoms) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPreviousPatientData?ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&Symptoms=' + Symptoms, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Departments for Admission/visitMark
   GetAdmissionVisitMarkDepartmentList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAdmissionVisitMarkDepartmentList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //  Loads all employee
   getEmploeeList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEmployeeList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads all doctor
   getDoctorList(ClietnID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorList?clientID=' + ClietnID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all doctor list using doctor type
   getDoctorListByDoctorType(DoctorType, ClietnID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorListByDoctorType?doctorType=' + DoctorType +
         '&clientID=' + ClietnID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Get Patient Past History
   GetPatientPastHistory(PatientID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientPastHistory?PatientID=' + PatientID +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all doctor
   // GetComponentList(ClietnID, type) {
   //    const customHeaders = this.globals.GetCustomHeadersForGet();
   //    return this.http.get<any>(environment.apiUrl + 'Common/GetComponentList?clientID=' + ClietnID + "&type=" + type,
   //       { headers: new HttpHeaders(customHeaders) })
   //       .pipe(
   //          catchError((error: any) => {
   //             throw error;
   //          }));
   // }


   //  Loads all User
   getUserList(suser) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUserList?user=' + suser, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads all User Rights for Details
   getRightsList(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRightsList/?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets all Cost Centre
   getCostCentreList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCostCentreList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets ReceiptBook List
   getReceiptBookList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReceiptBookList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of sponsor type
   getSponsorTypeList(sSponsorApplicable) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorTypeList?sSponsorApplicable=' + sSponsorApplicable,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of Group
   getGroupList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGroupList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   getPathologyServiceList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/getPathologyServiceList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of Class
   getClassList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClassList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Service List By Using Service Type
   getServiceListByServiceType(ServiceType: string) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceListByServiceType?ServiceType=' + ServiceType,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of group by using ClientID and ClassID
   getGroupListForClient(TariffID: number, ClassID: number, IsGroupCategory, DefaultTariffID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGroupListForClient?TariffID=' + TariffID +
         '&ClassID=' + ClassID + '&IsGroupCategory=' + IsGroupCategory + '&DefaultTariffID=' + DefaultTariffID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   // Getting details of Service
   GetClientServiceList(TariffID: number, ClassID: number, IsGroupCategory, DefaultTariffID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientServiceList?TariffID=' + TariffID +
         '&ClassID=' + ClassID + '&IsGroupCategory=' + IsGroupCategory + '&DefaultTariffID=' + DefaultTariffID, { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   // Getting details of Service
   GetClientServiceListForReport(TariffIds: string) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientServiceListForReport?TariffIds=' + TariffIds, { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   //  Loads all Sponsor
   getSponsorList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Sponsor By Selction Of SponsorType For Tariff
   getSponsorBySponsorTypeForTariff(SponsorTypeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorBySponsorTypeForTariff?sponsorTypeID=' + SponsorTypeID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Sponsor By Selction Of SponsorType
   getSponsorBySponsorType(SponsorTypeID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorBySponsorType?sponsorTypeID=' + SponsorTypeID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Tariff By Using SponsorTypeID , SponsorID , ClientID
   GetTariffBySponsorTypeandSponsor(SponsorTypeID, SponsorID, ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffBySponsorTypeandSponsor?sponsorTypeID=' + SponsorTypeID
         + '&sponsorID=' + SponsorID + '&clientID=' + ClientID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Service By Selction Of Group
   getServiceByGroupTariff(Group, TariffID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceByGroupTariff?group=' + Group
         + '&tariffID=' + TariffID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Components
   getComponentList(ClientID, type, ClientComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentList?clientID=' + ClientID + "&type=" + type + '&ClientComponentID=' + ClientComponentID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting hardware id
   GetHardwareID(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetHardwareID?ClientID=' + ClientID + "&LocationID=" + LocationID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetMacAddress(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMacAddress?ClientID=' + ClientID + "&LocationID=" + LocationID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // save  Hardware key 
   SaveHardwareID(HardwareId) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Common/SaveHardwareID?HardwareId=' + HardwareId,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Load all Control List Using ComponentID
   GetComponentControlList(ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentControlList?ComponentID=' + ComponentID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all validationType
   getValidationTypeList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetValidationTypeList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads validation Control Name
   getValidation(ClientID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetValidation?clientID=' + ClientID
         + '&componentID=' + ComponentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  get Client Ehr Label List
   GetClientEhrLabelList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientEhrLabelList?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Patients
   getPatient(FirstName, MiddleName, LastName, OPDNo, RegistrationNo, MobileNumber,
      RegistrationDate, OpIpFlag, IsDocterzClient, SmartSearch, ClientID, LocationID, DocterzRefreshToken) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/GetPatient?firstname=' + FirstName + '&middlename='
         + MiddleName + '&lastname=' + LastName + '&opd_admno=' + OPDNo + '&regno=' + RegistrationNo
         + '&mobile=' + MobileNumber + '&regdate=' + RegistrationDate + '&opIpFlag=' + OpIpFlag
         + '&isDocterzClient=' + IsDocterzClient + '&smartSearch=' + SmartSearch
         + '&clientID=' + ClientID + '&locationID=' + LocationID + '&DocterzRefreshToken=' + DocterzRefreshToken, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // get doctors by department Wise
   getDoctorByDepartment(DepartmentID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorByDepartment?departmentID=' + DepartmentID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   getCurrentDateTime() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCurrentDateTime', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Get Global Date
   getCurrentGlobalDate() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCurrentGlobalDate', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // get department by doctor wise
   getDepartmentByDoctor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDepartmentByDoctor/?deptID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Loads add referring Doctors
   getRefDoctorList(ClientID, LocationID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRefDoctorList?clientID=' + ClientID
         + '&locationID=' + LocationID + '&OpIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Tariff
   getTariffList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads selected patient
   getSelectedPatientList(PatientID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSelectedPatientList?patientID=' + PatientID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get sponsors applicable to client and default sponsor for that client
   GetSponsorApplicableAndDefaultSponsor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorApplicableAndDefaultSponsor?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) }).pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get Department and Doctor for that client
   GetDefaultDeprtmentAndDefaultDoctor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDefaultDeprtmentAndDefaultDoctor?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) }).pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get Password
   GetPassword(ModuleName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPassword?moduleName=' + ModuleName,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Patient Details From Common Controller
   getPatientDetails(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientDetails?opIpID=' + OpIpID
         + '&opIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Current Receipt Book Number For Inventory By Using ProcessType,StoreID, ClientID and LocationID
   GetInventoryReceiptBookNumber(ReceiptBookID: number, ClientID: number, LocationID: number, StoreID, IsMultipleStore) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryReceiptBookNumber?ReceiptBookID=' + ReceiptBookID +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID + '&StoreID=' + StoreID + '&IsMultipleStore=' + IsMultipleStore,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Removing token
   RemoveToken(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/RemoveToken?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Checking User is doctor or not by passing Userid , ClientId and LocationID
   IsUserDoctor(UserID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/IsUserDoctor?userID=' + UserID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Dose List from databases
   // This gives call to Get method from CommonController
   getDose(LanguageType) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoseList?LanguageType=' + LanguageType, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all When List from databases
   // This gives call to GetWhen method from CommonController
   getWhen(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWhen?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Duration List from databases
   // This gives call to GetDuration method from CommonController
   getDuration(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDuration?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting User Is Doctor Or Not and Default Language
   GetUserDoctorOrNotAndDefaultLanguage(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUserDoctorOrNotAndDefaultLanguage?UserID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Patient Details using PatientID , ClientID and LocationID
   GetPatientData(PatientID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientData?patientID=' + PatientID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology GroupID
   GetPathologyGroupID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathologyGroupID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Radiology GroupID
   GetRadiologyGroupID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRadiologyGroupID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Cash TariffID Using ClientID
   GetTariffID(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffID?ClientID=' + ClientID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Advance related details using PatientID
   GetPatientAdvanceDetails(PatientID, OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientAdvanceDetails?patientID=' + PatientID
         + '&opIpID=' + OpIpID + '&opIpFlag=' + OpIpFlag + '&ClientID=' + ClientID + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Building from database
   // This gives call to GetBuildingList method from CommonController
   GetBuildingList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetBuildingList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   // This function loads/displays all Floor from database
   // This gives call to GetFloorList method from CommonController
   GetFloorList(BuildingID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetFloorList?buildingID=' + BuildingID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Ward from database
   // This gives call to GetWardList method from CommonController
   GetWardList(FloorID, WardTypeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWardList?floorID=' + FloorID +
         '&wardTypeID=' + WardTypeID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Room from database
   // This gives call to GetRoomList method from CommonController
   GetRoomList(WardID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRoomList?wardID=' + WardID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function Gets City Name from database
   // This gives call to GetDefaultCity method from CommonController
   GetDefaultCity(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDefaultCity?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function Gets Latest Registration No from database
   // This gives call to GetLatestRegNo method from CommonController
   GetLatestRegNo(ClientID, LocationID, RegPrefix) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLatestRegNo?clientID=' + ClientID
         + '&locationID=' + LocationID + '&RegPrefix=' + RegPrefix, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Admission Details Of Patient Using AdmissionID
   GetAdmissionDetailsOfPatient(AdmissionID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAdmissionDetailsOfPatient?admissionID=' + AdmissionID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting IPD Patient List
   GetIPDPatientList(FirstName, MiddleName, LastName, ADMNo, RegNo, GSM1, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetIPDPatientList?firstName=' + FirstName + '&middleName=' + MiddleName +
         '&lastName=' + LastName + '&admNo=' + ADMNo + '&regno=' + RegNo + '&gsm1=' + GSM1 + '&fromDate=' + FromDate
         + '&toDate=' + ToDate + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Ward List
   GetClientWardList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientWardList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting ReceiptBookName From Common Controller
   getReceiptBookName(ReceiptBookID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReceiptBookName?receiptBookID=' + ReceiptBookID +
         '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Last Appointment Or Admission Sponsor Details
   GetLastSponsorDetails(PatientID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLastSponsorDetails?patientID=' + PatientID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Insurance List
   GetInsuranceList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInsuranceList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Corporate List
   GetCorporateList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCorporateList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get all fonts installed in the systems
   GetInstalledFontName() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInstalledFontName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Report List
   GetReportList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReportList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting ClassList By Client Location
   GetClassListByClientLocation(ClientID, LocationID, TariffType) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClassListByClientLocation?clientID=' + ClientID
         + '&locationID=' + LocationID + '&TariffType=' + TariffType, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Configuration Details Using Client Location
   GetConfigurationDetails(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetConfigurationDetails?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetGeneralConfigurationDetails() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGeneralConfigurationDetails', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Configuration Details Using CLient Location
   GetClientConfigurationDetails(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientConfigurationDetails?ClientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Tax List For Inventory
   GetTaxListForInventory(ClientState, SupplierID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTaxListForInventory?ClientState=' + ClientState
         + '&SupplierID=' + SupplierID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }



   // Getting Parameters List
   GetPathParamList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathParamList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting SubTest List
   GetSubTestList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSubTestList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Test Values Using PathTestID / PathSubTestID / PathParamID
   GetTestValues(PathTestID, PathSubTestID, PathParamID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTestValues?pathTestID=' + PathTestID
         + '&PathSubTestID=' + PathSubTestID + '&PathParamID=' + PathParamID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This method is used to get total no of doctor users for selected client
   GetTotalDoctorUserCount(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalDoctorUserCount?ClientID=' + ClientID
         + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This gives call to GetAvailableBedDetails method from CommonController
   GetAvailableBedDetails(ClientBuildingID, ClientWardID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAvailableBedDetails?clientBuildingID=' + ClientBuildingID +
         '&clientWardID=' + ClientWardID + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Ward List On Selection Of Building
   GetWardsList(BuildingID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWardsList?buildingID=' + BuildingID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   PrintAppointmentScheduler(SelectedDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/PrintAppointmentScheduler?SelectedDate=' + SelectedDate
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all frequency List from mstcommon table
   // This gives call to GetFrequency method from CommonController
   GetFrequency(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetFrequency?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting InventoryCommon List Using Inventory Common Name
   GetInventoryCommon(InventoryCommonName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryCommonName?InventoryCommonName=' +
         InventoryCommonName, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetManufacturerList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetManufacturerList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting InventoryCommon List Using Inventory Common Name
   GetTaxType(type) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTaxType?type=' + type, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Stores List
   GetStoresList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetStoresList?ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Supplier List
   GetSupplierList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSupplierList?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Inventory Department List Using ClientID and LocationID
   GetInventoryDepartmentList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryDepartmentList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Inventory Items List
   GetInventoryItemList(ItemName, SelectedItemID, StoreID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryItemList?value=' + ItemName
         + '&SelectedItemID=' + SelectedItemID + '&StoreID=' + StoreID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Tax Value Using ItemID and TaxID
   GetItemTaxValue(ItemID, TaxID, Type, BatchNo) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetItemTaxValue?ItemID=' + ItemID
         + '&TaxID=' + TaxID + '&Type=' + Type + '&BatchNo=' + BatchNo, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Latest Numbers For Inventory
   GetLatestInventoryNumber(InventoryType, Date) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLatestInventoryNumber?InventoryType=' + InventoryType
         + '&Date=' + Date, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used  to get discharge details of selected patient
   GetPatientDischargeDetails(AdmissionID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientDischargeDetails?AdmissionID=' + AdmissionID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This gives call to Get method from BillController
   getServiceRate(ServiceID, TariffID, ClassID, PatientID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceRate?serviceID=' + ServiceID
         + '&tariffID=' + TariffID + '&classID=' + ClassID + '&patientID=' + PatientID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Selected Group Pathology / Radiology Or Not
   GetIsPathlogyRadiologyGroupOrNot(GroupID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetIsPathlogyRadiologyGroupOrNot?GroupID=' + GroupID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Inventory Items List
   GetClientInventoryItemList(ItemName, SelectedItemID, StoreID, ClientID, LocationID, Type, PharmacyLocation) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientInventoryItemList?value=' + ItemName
         + '&SelectedItemID=' + SelectedItemID + '&StoreID=' + StoreID + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&Type=' + Type + '&PharmacyLocation=' + PharmacyLocation, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Inventory SubStore Items List
   GetClientInventorySubStoreItemList(ItemName, SelectedItemID, SubStoreID, ClientID, LocationID, Type, PharmacyLocation) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientInventorySubStoreItemList?value=' + ItemName
         + '&SelectedItemID=' + SelectedItemID + '&SubStoreID=' + SubStoreID + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&Type=' + Type + '&PharmacyLocation=' + PharmacyLocation, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Balance Quantity For Selected Item Using StoreID, ItemID, ClientID and LocationID
   GetBalanceQuantityOfSelectedItem(StoreID: number, SubStore: number, ItemID: number, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      let params = new HttpParams();
      params = params.append("StoreID", StoreID);
      params = params.append("SubStoreID", SubStore);
      params = params.append("ItemID", ItemID);
      params = params.append("ClientID", ClientID);
      params = params.append("LocationID", LocationID);
      return this.http.get<any>(environment.apiUrl + 'Common/GetBalanceQuantityOfSelectedItem',
         { headers: new HttpHeaders(customHeaders), params: params })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetDepartmentBalQty(SubstoreID, ItemID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      let params = new HttpParams();
      params = params.append("SubstoreID", SubstoreID);
      params = params.append("ItemID", ItemID);
      params = params.append("ClientID", ClientID);
      params = params.append("LocationID", LocationID);
      return this.http.get<any>(environment.apiUrl + 'Common/GetDepartmentBalQty',
         { headers: new HttpHeaders(customHeaders), params: params })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Request Charges of OpIpID and OpIpFlag
   GetDoctorRequestCharges(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorRequestCharges?OpIpID=' + OpIpID
         + '&OpIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Selected Option (OPD , IPD, Staff) List Using Option, ClientID, LocationID , FormDate And ToDate
   GetSelectedOpdIpdDoctorStaffList(OptionType, ClientID, LocationID, FromDate, ToDate) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSelectedOpdIpdDoctorStaffList?OptionType=' + OptionType
         + '&ClientID=' + ClientID + '&LocationID=' + LocationID + '&FromDate=' + FromDate + '&ToDate=' + ToDate,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function gets Print Opd / Ipd Patient Advance from database
   // This gives call to GetTotalAdvance method from CommonController
   GetTotalAdvance(OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalAdvance?opIpID=' + OpIpID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function gets Print Opd / Ipd Patient Advance from database
   // This gives call to GetTotalAdvance method from CommonController
   ViewAdvanceReceipt(PaymentID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/ViewAdvanceReceipt?paymentID=' + PaymentID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   ViewPaymentReceipt(PaymentID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/ViewPaymentReceipt?paymentID=' + PaymentID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Printing Refund of Advance By Passing OpIpID and OpIpFlag
   GetTotalRefundAdvance(OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalRefundAdvance?opIpID=' + OpIpID +
         '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Printing GetPrintOPDCasePaperBy Passing OpIpID and OpIpFlag
   GetPrintOPDCasePaper(OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPrintOPDCasePaper?opIpID=' + OpIpID +
         '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Printing Bill Settlement Report
   // This gives call to GetPrintSettlementReport method from CommonController
   GetPrintSettlementReport(OpIpID, BillID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPrintSettlementReport?OpIpID=' + OpIpID +
         '&BillID=' + BillID + '&OpIpFlag=' + OpIpFlag + '&ClientID=' + ClientID + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetICUChartDetails(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetICUChartDetails?OpIpID=' + OpIpID +
         '&OpIpFlag=' + OpIpFlag,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting User's DoctorID using UserID
   // This gives call to GetUsersDoctorID method from CommonController
   GetUsersDoctorID(UserID, type) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUsersDoctorID?UserID=' + UserID + '&type=' + type,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all menu for user
   // This gives call to GetMenuItems method from CommonController
   GetMenuItems(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMenuItems?UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  search patient on given critetia
   PatientSmartSearch(SearchTerm, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/PatientSmartSearch?SearchTerm=' + SearchTerm + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Update pan and adhar number
   GetUpdateAadharPanNumber(PatientID, ClientID, LocationID, ADHARNumber, PANNumber, type, AdahrPanTitle) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/GetUpdateAadharPanNumber?PatientID=' + PatientID + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&ADHARNumber=' + ADHARNumber + '&PANNumber=' + PANNumber + '&type=' + type + '&Title=' + AdahrPanTitle, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Common Template List Using ClientID, LocationID and ComponentID
   GetClientCommonTemplateList(ClientID, LocationID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientCommonTemplateList?ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&ComponentID=' + ComponentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   // Getting Inventory Department List Using ClientID and LocationID
   GetAllKPIList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllKPIList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Get All Report List by licence
   GetAllReportList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllReportList?ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Tariff, Sponsor, SponsorType List Using ClientID
   GetClientTariffSponsorSponsorTypeList(ClientID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientTariffSponsorSponsorTypeList?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Checked Is User Token Same Or Not
   GetCheckUserTokenValidOrNot() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCheckUserTokenValidOrNot', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Operation Theatre Smart Search Result
   OTPatientSmartSearch(SearchTerm: string, OpIpFlag: boolean, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/OTPatientSmartSearch?SearchTerm=' + SearchTerm + '&OpIpFlag=' + OpIpFlag +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Template List By Passing Category Name , ClientID and LocationID
   GetTemplateListByCategoryName(CategoryName: string, DoctorIDs: string, DepartmentIDs: string, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTemplateListByCategoryName?CategoryName=' + CategoryName +
         '&DoctorIDs=' + DoctorIDs + '&DepartmentIDs=' + DepartmentIDs + '&ClientID=' + ClientID + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Template Details By Passing Template Group Name , ClientID and LocationID
   GetTemplateDetailsByTemplateGroupName(TemplateGroupName: string, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTemplateDetailsByTemplateGroupName?TemplateGroupName=' + TemplateGroupName
         + '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Checking user password is matching or not
   IsPasswordMatchingForUser(UserName, Password) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/IsPasswordMatchingForUser?UserName=' + UserName
         + '&Password=' + Password, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Approving User Details
   UserApproveDetails(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/UserApproveDetails?userID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Building Floor Ward Room Bed List Using ClientID
   GetClientBuildingFloorWardRoomBed(ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientBuildingFloorWardRoomBed?ClientID=' + ClientID +
         '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Asset Stores from mstconfiguration
   GetAssetStoreID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAssetStoreID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology Report Status
   GetPathologyReportStatus(AppAdmNo: string, ClientID: number, LocationID: number) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("AppAdmNo", AppAdmNo);
      httpParams = httpParams.append("ClientID", ClientID);
      httpParams = httpParams.append("LocationID", LocationID);
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathologyReportStatus',
         { headers: new HttpHeaders(customHeaders), params: httpParams })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology Report
   GetPathologyReport(AppAdmNo: string, ClientID: number, LocationID: number) {
      const options = { responseType: 'blob' as 'json' };
      return this.http.get<Blob>(environment.apiUrl + 'Common/GetPathologyReport?AppAdmNo=' + AppAdmNo + '&ClientID=' + ClientID + '&LocationID=' + LocationID, options)
         .pipe(map(res => new Blob([res], { type: 'application/pdf' })));

   }

   // Getting Selected Service Tax
   GetSelectedServiceTax(ServiceID: number) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("ServiceID", ServiceID);
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSelectedServiceTax',
         { headers: new HttpHeaders(customHeaders), params: httpParams })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetPaymentDetailsByBillID(BillID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPaymentDetailsByBillID?BillID=' + BillID +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // getting authentication rights of component for the selected user
   GetAuthenticationTypeComponent(ComponentPath, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAuthenticationTypeComponent?ComponentPath=' + ComponentPath
         + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //this function is used to get group and service list for OT slip module
   GetGroupAndService(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGroupAndService?OpIpID=' + OpIpID + '&OpIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetClientCheckList(ClientID, LocationID, CategoryName, FieldName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientCheckList?ClientID=' + ClientID +
         '&LocationID=' + LocationID + '&CategoryName=' + CategoryName + '&FieldName=' + FieldName, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Configuration Details
   GetComponentTabList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentTabList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetClientComponentTabList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientComponentTabList?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetComponentWiseTabList(ComponentID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentWiseTabList?ClientID=' + ClientID +
         '&LocationID=' + LocationID + '&ComponentID=' + ComponentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting State and City
   GetStateList(lable, StateName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/GetStateList?lable=' + lable + '&StateName='
         + StateName, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting ReceitpBook Name And SponsorType
   GetReceiptBookDetails(OpIp, SponsorType, TargetPage): string {
      let receiptbook = '';

      // OPD Or IPD
      if (OpIp === true) {
         receiptbook += 'OPD';
      } else {
         receiptbook += 'IPD';
      }

      // Sponsor Type   || +SponsorType === 17 || +SponsorType === 19
      if (+SponsorType === 1) {
         receiptbook += ' Cash';
      } else {
         receiptbook += ' Invoice';
      }

      // Target Page
      if (TargetPage === 'Bill') {
         receiptbook += '';
      } else if (TargetPage === 'Payment') {
         receiptbook += ' Settlement';
      } else if (TargetPage === 'Advance') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Advance';
         } else {
            receiptbook += 'IPD Advance';
         }
      } else if (TargetPage === 'Refund Advance') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Refund Advance';
         } else {
            receiptbook += 'IPD Refund Advance';
         }
      } else if (TargetPage === 'Refund Bill') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Refund Bill';
         } else {
            receiptbook += 'IPD Refund Bill';
         }
      } else if (TargetPage === 'Short') {
         receiptbook = '';
         receiptbook += 'IPD Short Bill';
      }

      // Setting Globals SponsorType
      SponsorType = '';
      const receiptbookname = receiptbook.replace(/\s+/g, '');
      SponsorType = this.globals[receiptbookname];

      return receiptbook + ',' + SponsorType;
   }

   //  Get Add Charges Automatic to Admitted Patient
   SaveAutoServiceCharges(ClientID, LocationID, UserName, DefaultTariffID) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Common/SaveAutoServiceCharges?ClientID=' + ClientID + '&LocationID=' + LocationID +
         '&UserName=' + UserName + '&DefaultTariffID=' + DefaultTariffID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAutoServiceChargesData(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAutoServiceChargesData?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAutoChargePrint(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAutoChargePrint?ClientID=' + ClientID +
         '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //search patient 
   GetPatientNamebySearchTerm(SearchTerm) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientNamebySearchTerm?SearchTerm=' + SearchTerm, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetPrintOPDCollection(clientID, locationID, fromdate, todate, clientInstitution, clientName, locationAddress) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetOPDCollection?clientID=' + clientID +
         '&locationID=' + locationID + '&fromdate=' + fromdate + '&todate=' + todate + '&clientInstitution=' + clientInstitution + 
         '&clientName=' + clientName + '&locationAddress=' + locationAddress,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
}
