import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/shared/common.service';
import { GlobalService } from 'app/shared/global.service';
import { UserLogin } from 'app/shared/models/user-login.model';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { AuthService } from '../../service/auth/auth.service';
import { LoginService } from './login.service';

@Component({
  selector: 'ms-login-session',
  templateUrl: './loginone-component.html',
  styleUrls: ['./loginone-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LoginoneComponent implements OnInit, OnDestroy {

  loginSubscription: Subscription = new Subscription();
  loginForm: FormGroup;

  password: string = "";
  name: string = "";
  isActive: boolean = false;
  isInProgress: boolean = false;
  userLogin: UserLogin = new UserLogin();

  constructor(private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    public translate: TranslateService,
    private globals: GlobalService,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef) {
  }



  fetchDateTime() {
    this.commonService.getCurrentDateTime().subscribe((data) => {
      if (data) {
        this.globals.CurrentDateTime = data;
      }
    });
  }

  // when email and password is correct, user logged in.
  async logIn() {
    this.isInProgress = true;
    this.userLogin = this.loginForm.value;
    this.loginSubscription = await this.loginService.checkLogin(this.userLogin).subscribe((data) => {
      this.isInProgress = false;
      if (data === 401) {
        // Login Checking Valid Or Not Using data
        this.resteLoginForm();
        this.toasterService.error("Please enter valid credentials!");
        // require view to be updated
        this.ref.markForCheck();
      } else if (data == "expired") {
        this.toasterService.warning("Your software subscription has expired. please contact 7030560606 or 9511209020 to renew.");
      }
      else {
        this.resteLoginForm();
        this.toasterService.success("You have been successfully logged In!");
        this.authService.setToken(data);

        this.resteLoginForm();


        // Navigating To Dashboard
        this.router.navigate(['/dashboard']);
        //this.fetchDateTime();


        // require view to be updated
        this.ref.markForCheck();
      }

      this.isInProgress = false;
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  resteLoginForm(): void {
    this.loginForm.reset();
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      UserName: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(15)]),
      Password: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
    });

    // Automatic Logout if token exists and page is reloaded (bookmark click)
    if (sessionStorage.getItem('token') !== null) {
      this.authService.logOut();
    }

    this.globals.SetDefaultValuesForGlobal();

    // Checking token Value Is There Or Not
    if (sessionStorage.getItem('token') !== null) {
      // Removing Settings
      sessionStorage.removeItem('token');
    }

    // Checking Settings Value Is There Or Not
    if (sessionStorage.getItem('settings') !== null) {
      // Removing Settings
      sessionStorage.removeItem('settings');
    }

    const btnClose = document.getElementById("closed")! as HTMLButtonElement;
    if (btnClose) {
      btnClose.click();
    }
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
    window.onbeforeunload = () => {
      this.authService.logOut();
    };
  }
}
